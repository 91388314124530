import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyCraigClemensTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Craig Clemons"
        description="Hook Points help turn Golden Hippo Media’s Co-Founder into a personal brand"
        image={image}
      />
      <CaseStudyDescriptionSection
        list={
          <ul>
            <li>
              We guided business Co-Founder Craig Clemens to{" "}
              <span>expand his personal brand</span> and thought leadership
              through content on digital platforms
            </li>
            <li>
              Created strategic Hook Points for Craig’s content and creative
              that helped him stand out and <span>grab attention</span> among
              audiences online
            </li>
            <li>
              Increased Instagram following to <span>800,000</span> and growing
              while generating <span>millions of views</span> on IGTV and
              Instagram posts
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-tylor-swift"}
        text="We’ve got more to tell about our work with Craig. "
        subText=" Let’s talk"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyCraigClemensTemplate

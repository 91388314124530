import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyCraigClemensTemplate from "../modules/caseStudy/CaseStudyCraigClemens.template"

const CaseStudyCraigClemens = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study Craig Clemens" />
      <CaseStudyCraigClemensTemplate image={data.caseStudyCraigClemens.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyCraigClemens: allFile(
      filter: { relativeDirectory: { eq: "caseStudyCraigClemens" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyCraigClemens
